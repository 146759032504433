<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import { getUserName } from "@/services/api/iam.api";

const props = defineProps({
  userId: {
    type: String,
    default: undefined,
  },
  addModus: {
    type: Boolean,
    default: false,
  },
});

const breadCrumbName = ref(undefined);

const breadcrumbs = computed(() => {
  let list = [{ default: "IAM-UsersOverview" }];
  if (props.addModus) {
    list.push({
      text: "toevoegen",
      disabled: true,
    });
  } else {
    list.push({
      text: breadCrumbName.value,
      to: { name: "IAM-UserDetails", params: { userId: props.userId } },
    });
  }
  return list;
});

watch(
  () => props.userId,
  () => {
    getBreadCrumbName();
  },
  {
    immediate: true,
  }
);

async function getBreadCrumbName() {
  console.log("user getBreadCrumbName");
  if (!props.addModus) {
    const response = await getUserName(props.userId);
    breadCrumbName.value = response.data;
  }
}

function updateBreadCrumbName() {
  console.log("update breadCrumbName user");
  getBreadCrumbName();
}
</script>
<template>
  <v-container fluid pa-0 class="app-iam">
    <inn-breadcrumbs :items="breadcrumbs"></inn-breadcrumbs>
    <v-container fluid>
      <header class="app-welcome-message">
        <h2><v-icon>mdi-account</v-icon> Gebruiker</h2>
      </header>
      <v-row>
        <v-col cols="2" class="sidebar">
          <v-list nav>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-UserDetails' }" :disabled="addModus">
                <v-list-item-title>Details</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-UserUserGroups' }" :disabled="addModus">
                <v-list-item-title>Groepen</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-divider></v-divider>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-UserResources' }" :disabled="addModus">
                <v-list-item-title>Resources</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-UserProjects' }" :disabled="addModus">
                <v-list-item-title>Projecten</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-UserRoles' }" :disabled="addModus">
                <v-list-item-title>Rollen</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-divider></v-divider>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-UserLogins' }" :disabled="addModus">
                <v-list-item-title>Logins</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col>
          <router-view @update-user="updateBreadCrumbName"></router-view>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
